import {formInterceptor} from "./form-interceptor.ts";
import {z} from "zod";

const schema = z.object({
  email: z.string().email(),
});

export function NewsletterForm() {
  formInterceptor<{message: string}>({
    selector: '[data-form="newsletter"]',
    schema,
    useRecaptcha: false,
    onSuccess({response}) {
      if(response) {
        window.Toast.show({
          type: 'success',
          message: response.message,
        });
      }
    }
  });
}
