export function YoutubeModal() {
    const body = document.querySelector("body");

    const youtubeModal = document.getElementById('youtube-modal');
    const youtubeIframe = youtubeModal?.querySelector('iframe');
    const closeYoutubeModal = document.getElementById('close-youtube-modal');

    const modal = document.querySelector<HTMLElement>('[data-modal]');
    if(youtubeModal && modal?.dataset.modal === 'youtube') {
        modal.addEventListener('click', () => {
            const src = modal.dataset.src;
            if(youtubeIframe && src) {
                youtubeIframe.src = src;

                youtubeModal.classList.remove('invisible');
                youtubeModal.classList.add('opacity-100');

                body?.classList.add('overflow-hidden');
            }
        });
    }

    if(closeYoutubeModal) {
        closeYoutubeModal.addEventListener('click', () => {
            youtubeModal?.classList.remove('opacity-100');
            youtubeModal?.classList.add('invisible');

            body?.classList.remove('overflow-hidden');

            if(youtubeIframe) youtubeIframe.src = '';
        });
    }
}
