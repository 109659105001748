import {ZodSchema} from "zod";
import { load as loadRecaptcha } from 'recaptcha-v3';

type FormHandlerSuccess<SuccessReturn> = {
  response?: SuccessReturn
  form?: HTMLFormElement;
};
type FormHandlerProps<SuccessReturn, Schema> = {
    selector: string;
    schema: ZodSchema;
    showRequiredMessage?: boolean;
    useRecaptcha?: boolean;
    onValidationSuccess?: (data: Schema, submitter: HTMLButtonElement) => void;
    onError?: (e?: unknown) => void;
    onSuccess?: (props: FormHandlerSuccess<SuccessReturn>) => void;
};

export function formInterceptor<SuccessReturn, Schema>(props: FormHandlerProps<SuccessReturn, Schema>) {
    const {
      selector,
      schema,
      showRequiredMessage = true,
      useRecaptcha = true,
      onValidationSuccess,
      onError,
      onSuccess,
    } = props;
    const forms = document.querySelectorAll<HTMLFormElement>(selector);

    for(const form of forms) {
        form.addEventListener('submit', async (e) => {
            e.preventDefault();

            const submitButton = e.submitter as HTMLButtonElement;
            submitButton.classList.add('loading');

            const formData = new FormData(form);

            const data: Record<string, string> = {};
            for(const [key, value] of formData.entries()) {
              const inputContainer = form.querySelector(`[data-container="${key}"]`);
              if(inputContainer) {
                inputContainer.classList.remove('invalid');

                const spanError = inputContainer.querySelector('.error-message');
                if(spanError) inputContainer.removeChild(spanError);
              }

              data[key] = value?.toString();
            }

            const result = schema.safeParse(data);
            if(result.success) {
              const handler = form.getAttribute('data-request-method') || '';
              try {
                if(handler) {
                  const action = form.action;
                  const formData = new FormData(form);

                  if(useRecaptcha) {
                    // console.log('recaptcha...')
                    const recaptcha = await loadRecaptcha(import.meta.env.VITE_RECAPTCHA_KEY, {autoHideBadge: true});
                    const token = await recaptcha.execute('submit');
                    // console.log('recaptcha', token)
                    formData.append('token', token);
                  }
                  // console.log('fetch')
                  const response = await fetch(action, {
                      body: formData,
                      method: 'POST',
                      headers: {
                        'X-October-Request-Handler': handler,
                        'X-Requested-With': 'XMLHttpRequest',
                      }
                  });
                  // console.log('fetch ended', response)

                  // console.log('json')
                  const data = await response.json();
                  // console.log('json', data)

                  if(data.success || data.status) {
                    // console.log('success')
                    onSuccess?.({response: data as SuccessReturn, form});
                  } else {
                    onError?.(data.message);

                    window.Toast.show({
                      type: 'error',
                      message: data.message,
                    })
                  }
                }
              } catch (e) {
                  window.Toast.show({
                      type: 'error',
                      message: JSON.stringify(e)
                  })
              } finally {
                if(handler) submitButton.classList.remove('loading');
              }

              onValidationSuccess?.(result.data, submitButton);
            } else {
              const fieldErrors = result.error.formErrors.fieldErrors;
              const fieldKeys = Object.keys(fieldErrors);

              console.log(fieldErrors);

              for(const key of fieldKeys) {
                const inputContainer = form.querySelector(`[data-container="${key}"]`);
                if(inputContainer) {
                  inputContainer.classList.add('invalid');

                  if(showRequiredMessage) {
                    const spanError = document.createElement('span');
                    spanError.classList.add('error-message');
                    spanError.innerText = fieldErrors[key]?.[0] || 'This field is required';

                    inputContainer.appendChild(spanError);
                  }
                }
              }

              submitButton.classList.remove('loading');
            }
        });
    }
}
