import {formInterceptor} from "./form-interceptor.ts";
import {z} from "zod";

const schema = z.object({
  fname: z.string().min(1, 'This field is required'),
  lname: z.string().min(1, 'This field is required'),
  email: z.string().email('This field is required'),
  number: z.string().min(1, 'This field is required'),
  company: z.string().min(1, 'This field is required'),
  industry: z.string().min(1, 'This field is required'),
  msg: z.string().min(1, 'This field is required'),
});

export function ContactForm() {
  formInterceptor({
    selector: '[data-form="contact"]',
    schema,
    onSuccess({form}) {
      form?.parentElement?.classList.add('success');
    }
  });
}
