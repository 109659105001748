import {z} from "zod";
import {formInterceptor} from "./form-interceptor.ts";

const schema = z.object({
  firstname: z.string().min(3, 'Please enter your name'),
  lastname: z.string().default(''),
  site: z.string().default('My Company'),
  rosterFirstDay: z.string().default('1'),
  username: z.string().email('Please enter a valid email'),
  mobilecountry: z.string(),
  mobilenumber: z.string().min(4, 'Please enter a valid phone number'),
}).catchall(z.string());
type Schema = z.infer<typeof schema>;

export function RegistrationModal() {
  const apiURL = window.apiURL || 'https://api.dev.rosterelf.net/2.0.0';
  const webappURL = window.webappURL || 'http://localhost:3000';

    const body = document.querySelector("body");

    const registrationModal = document.getElementById('registration-modal');
    const closeRegistrationModal = document.getElementById('close-registration-modal');

    const btnsOpenModal = document.querySelectorAll<HTMLElement>('[data-modal]');

    if(btnsOpenModal.length) {
      for(const modal of btnsOpenModal) {
        if(modal?.dataset.modal === 'registration') {
          modal.addEventListener('click', () => {
            registrationModal?.classList.remove('invisible');
            registrationModal?.classList.add('opacity-100');
            body?.classList.add('overflow-hidden');
          });
        }
      }

      const countryCodeSpan = registrationModal?.querySelector('[data-mobilecountrySpan="true"]');
      const selectCountries = registrationModal?.querySelector('select');
      selectCountries?.addEventListener('change', (event) => {
        const select = event.target as HTMLSelectElement;
        if(countryCodeSpan) countryCodeSpan.innerHTML = '+' + select.value;
      });

      type FormReturn = {
        accountName: string;
        sid: string;
      };
      formInterceptor<FormReturn, Schema>({
        selector: '[data-form="registration"]',
        schema,
        useRecaptcha: false,
        async onValidationSuccess(data, submitter) {
          console.log('onValidationSuccess');

          try {
            const response = await fetch(`${apiURL}/register`, {
              method: 'POST',
              body: JSON.stringify(data),
            });

            const responseJson = await response.json();

            if(response.status !== 200) throw new Error(responseJson.exception);

            fetch(`${apiURL}/set-registration-success`, {
              method: 'POST',
              body: JSON.stringify({params: data}),
            });

            const success = responseJson as FormReturn;
            window.location.href = `${webappURL}/registration-success?sid=${success.sid}`;
          } catch (e: unknown) {
            const error = e as Error;
            window.Toast.show({
              type: 'error',
              message: error.message,
            });
          } finally {
            submitter.classList.remove('loading');
          }
        },
        onError(e) {
          console.log(e);
        }
      });

      if(closeRegistrationModal) {
        closeRegistrationModal.addEventListener('click', () => {
          registrationModal?.classList.remove('opacity-100');
          registrationModal?.classList.add('invisible');

          body?.classList.remove('overflow-hidden');
        });
      }
    }
}
