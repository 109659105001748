export function GleenChat() {
  if(window.chatSource === 'gleen') {
    setTimeout(() => {
      document.gleenConfig = {
        company: "rosterelf"
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'gleen-chat';
      script.async = true;
      script.dataset.cmpAb = '2'; // allow script to be executed without iubenda's consent
      script.src = 'https://app.gleen.ai/sdk/gleenWidget.js';

      script.onload = () => console.log('Gleen bot script loaded successfully');
      script.onerror = console.log;

      document.body.appendChild(script);
    }, 5000);
  }
}
