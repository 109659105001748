import {formInterceptor} from "./form-interceptor.ts";
import {z} from "zod";

const schema = z.object({
  fname: z.string().min(1, 'This field is required'),
  lname: z.string().min(1, 'This field is required'),
  email: z.string().email(),
  country: z.string().optional(),
});

export function DownloadPDFForm() {
  formInterceptor({
    selector: '[data-form="download-team-building-pdf"]',
    schema,
    onSuccess() {
      window.Toast.show({
        type: 'success',
        message: `We've sent you an email. Thank you!`,
      });
    }
  });
}
